import React, { FC } from 'react'
import styled from 'styled-components'

import SEO from '../components/seo'
import Navigation from '../components/navigation'
import { StaticImage } from 'gatsby-plugin-image'

const HeroSection = styled.div`
	position: relative;
	width: 100%;
	background-color: ${p => p.theme.colors.yves};
	color: ${p => p.theme.colors.silver};
`

const Content = styled.div`
	max-width: 1024px;
	height: 100%;
	margin: 0 auto;
	padding-left: 2rem;
	padding-right: 2rem;

	h1 {
		margin-top: 0;
		margin-bottom: 1rem;
		font-weight: normal;
	}

	section {
		padding: 2rem 0;
	}

	h1 {
		padding-top: 2rem;
		margin-bottom: 1rem;
	}

	a {
		color: ${p => p.theme.colors.silver};

		cursor: pointer;
		text-decoration: underline;

		:hover {
			text-decoration: underline;
		}

		&::visited {
			color: ${p => p.theme.colors.silver};
		}
	}
`
const ReplyLink = styled.a`
	display: inline-flex;
	border: 1px solid ${p => p.theme.colors.silver};
	text-decoration: none;
	margin-top: 1rem;
	padding: 1rem;

	color: ${p => p.theme.colors.silver};

	cursor: pointer;

	:hover {
		color: ${p => p.theme.colors.yves};
		background: ${p => p.theme.colors.silver};
	}

	:visited {
		color: ${p => p.theme.colors.silver};

		:hover {
			color: ${p => p.theme.colors.yves};
		}
	}

	& + & {
		margin-left: 0.5rem;
	}
`

const AccomodationPage: FC<{}> = () => (
	<>
		<SEO title="Accomodation" />
		<HeroSection>
			<Content>
				<Navigation />
				<section>
					<h1>Accomodation</h1>
					<p>
						For those of you who need accomodation for the wedding weekend,
						we've pre-booked rooms at City Hotel Satu Mare. It's a 4 star hotel
						in a very quiet and central location. It's a 5-10 minute walk from
						the city center and it's close to both Laura and Emil's. For
						pictures and more information about the facilities visit their
						Booking.com page{' '}
						<a
							href="https://www.booking.com/hotel/ro/satu-mare-city.en-gb.html"
							target="_blank"
						>
							here
						</a>
						.
					</p>
					<h1>Payment and check-in</h1>
					<p>The daily rates negotiated for you are:</p>
					<ul>
						<li>€65/night for a double room</li>
						<li>€45/night for a single room</li>
					</ul>
					<p>
						The price includes daily breakfast and parking. You can check in at
						any time when you arrive. Payment will be taken on checkout on
						Sunday.
					</p>
					<h1>Booking a room</h1>
					<p>
						If you need accomodation and you haven't already discussed it with
						us, please contact Laura.
					</p>
					<ReplyLink target="_blank" href="https://wa.me/447599371114">
						Whatsapp Laura
					</ReplyLink>
				</section>
			</Content>
		</HeroSection>
	</>
)

export default AccomodationPage
